import React from 'react'

import { Footer } from './Footer'
import { Header } from './Header'
import css from './Layout.module.css'


export const Layout: React.FC<React.PropsWithChildren> = ({ children }) => (
  <>
    <div className={css.header}><Header /></div>
    <div className={css.main}>{children}</div>
    <Footer />
  </>
)
