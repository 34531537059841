import React from 'react'
import FilePdfTwoTone from '@ant-design/icons/FilePdfTwoTone'
import Modal from 'antd/es/modal'

import { fxLogoutUser, fxUserAgree } from '../account.model'
import css from './Consent.module.css'


const pdfIcon = <FilePdfTwoTone twoToneColor="#F15642" className={css.icon} />

// пользователь должен явно кликнуть кнопки ДА/НЕТ
export const Consent: React.FC = () => (
  <Modal
    title="Для продолжения работы необходимо принять условия пользования сайтом"
    okText="Принять"
    cancelText="Отклонить"
    onOk={() => fxUserAgree()}
    onCancel={() => fxLogoutUser()}
    maskClosable={false}
    closeIcon={null}
    centered
    open
  >
    <div className={css.container}>
      <a
        className={css.link}
        href="/docs/terms_of_use.pdf"
        data-fileSize="142 Кб"
        target="_blank"
      >
        {pdfIcon} Условия пользования Сайтом
      </a>
    </div>
  </Modal>
)
