import React from 'react'
import { useNavigate } from 'react-router-dom'
import LogoutOutlined from '@ant-design/icons/LogoutOutlined'
import Typography from 'antd/lib/typography'

import { fxLogoutUser } from '../account.model'


export const Logout = () => {
  const navigate = useNavigate()

  const logoutWithRedirect = () => {
    fxLogoutUser()
    navigate('/', { replace: true })
  }

  return (
    <Typography.Text onClick={logoutWithRedirect} style={{ color: 'var(--red-600)', display: 'block' }}>
      <LogoutOutlined /> Выйти
    </Typography.Text>
  )
}
