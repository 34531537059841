import React from 'react'
import PhoneOutlined from '@ant-design/icons/PhoneOutlined'
import Typography from 'antd/es/typography'

import { getDocumentTitle } from '../../mappers/getDocumentTitle'
import css from './Contacts.module.css'


const emailIcon = <div className={css.icon}>@</div>
const phoneIcon = <div className={css.icon}><PhoneOutlined /></div>

export const Contatcs = () => {
  React.useEffect(() => {
    document.title = getDocumentTitle('Контакты')
  }, [])

  return (
    <div className={css.root}>
      <Typography.Title level={2}>Контакты</Typography.Title>
      <main className={css.main}>
        <section className={css.section}>
          <Typography.Title level={4}>Договорной отдел</Typography.Title>
          <ul>
            <li>{emailIcon} Электронная почта: <a href="mailto:contract@smarttravel.ru" data-pseudoLink>contract@smarttravel.ru</a></li>
          </ul>
        </section>
        <section className={css.section}>
          <Typography.Title level={4}>Отдел по работе с отелями</Typography.Title>
          <ul>
            <li>{emailIcon} Электронная почта: <a href="mailto:hms@smarttravel.ru" data-pseudoLink>hms@smarttravel.ru</a></li>
            <li>{phoneIcon} Телефон: <a href="tel:+74952601916" data-pseudoLink>+7 495 260 19 16</a></li>
          </ul>
        </section>
        <section className={css.section}>
          <Typography.Title level={4}>Отдел по работе с бронированиями</Typography.Title>
          <ul>
            <li>{emailIcon} Электронная почта: <a href="mailto:booking@smarttravel.ru" data-pseudoLink>booking@smarttravel.ru</a></li>
            <li>{phoneIcon} Для бесплатных звонков по России: <a href="tel:88005000587" data-pseudoLink>8 800 500 05 87</a></li>
            <li>{phoneIcon} Для звонков из других стран: <a href="tel:+74958467339" data-pseudoLink>+7 495 846 73 39</a></li>
          </ul>
        </section>
        <section className={css.section}>
          <Typography.Title level={4}>Отдел по расчетам</Typography.Title>
          <ul>
            <li>{emailIcon} Электронная почта: <a href="mailto:reports_travel@smarttravel.ru" data-pseudoLink>reports_travel@smarttravel.ru</a></li>
          </ul>
        </section>
      </main>
    </div>
  )
}
