/* istanbul ignore file */
import React from 'react'
import ReactDOM from 'react-dom/client'
import { ConfigProvider } from 'antd'
import locale from 'antd/locale/ru_RU'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

import { App } from './App'
import 'dayjs/locale/ru'
import 'antd/dist/reset.css'
import 'draft-js/dist/Draft.css'


dayjs.extend(utc)
dayjs.extend(timezone)


ReactDOM
  .createRoot(document.getElementById('root') as HTMLElement)
  .render(<ConfigProvider locale={locale}><App /></ConfigProvider>)
