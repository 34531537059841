import React from 'react'
import { Flex } from 'antd'

import { getDocumentTitle } from '../../mappers/getDocumentTitle'
import { routes } from '../../routes'
import css from './Footer.module.css'


const year = new Date().getFullYear()

export const Footer: React.FC = () => (
  <footer className={css.footer}>
    <nav className={css.nav}>
      <ul>
        <li><a href={routes.contacts()} target="_blank" rel="noreferrer" data-ym-click="contact_page_click">Контакты</a></li>
        <li><a href="/docs/instruction.pdf" target="_blank" data-ym-click="instructions_page_click">Инструкция по использованию</a></li>
        <li><a href="/docs/terms_of_use.pdf" target="_blank" data-ym-click="terms_of_use_page_click">Условия пользования Сайтом</a></li>
      </ul>
    </nav>
    <Flex align="center" gap={4}>
      <img src="/tm-logo.svg" alt={getDocumentTitle()} className={css.logo} />
      <span>© {year}. ТУРМАШИНА. Все права защищены.</span>
    </Flex>
  </footer>
)
