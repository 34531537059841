/* istanbul ignore file */
import React from 'react'


const View = React.lazy(() => import('./index'))

export const HotelList = () => (
  <React.Suspense>
    <View />
  </React.Suspense>
)
