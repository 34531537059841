/* istanbul ignore file */
export class Token {
  constructor(private key: string) {}

  get() {
    return localStorage.getItem(this.key)
  }

  set(value: string) {
    localStorage.setItem(this.key, value)
  }

  remove() {
    localStorage.removeItem(this.key)
  }
}
