import React from 'react'
import { AllowedTo } from 'react-abac'
import { Navigate, useLocation } from 'react-router-dom'
import { useUnit } from 'effector-react/effector-react.umd'

import { routes } from '../routes'
import { $account, $isAcceptedTerms, $isAppInitialized } from './account.model'
import { Consent } from './components/Consent'


export const ProtectedRoute: React.FC<React.PropsWithChildren<{
  grants?: string[] // ограничения установленные для маршрута
}>> = ({
  grants,
  children,
}) => {
  const account = useUnit($account)
  const isAppInitialized = useUnit($isAppInitialized)
  const isAcceptedTerms = useUnit($isAcceptedTerms)
  const location = useLocation()

  if (!isAppInitialized) return null
  if (!account) return <Navigate to={routes.login()} replace state={{ from: location }} />
  if (!isAcceptedTerms) return <Consent />
  if (!grants || grants.length === 0) return <>{children}</> // можно смотреть если нет ограничений

  return (
    <AllowedTo
      perform={grants}
      no={() => <>Ничего не найдено</>}
    >
      {children}
    </AllowedTo>
  )
}
