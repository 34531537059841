/* istanbul ignore file */
/* eslint-disable max-len */
import React from 'react'
import { AbacProvider } from 'react-abac'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { useUnit } from 'effector-react'

import { $account, fxInitApp } from './auth/account.model'
import { Layout } from './auth/components/Layout'
import { Login } from './auth/components/Login'
import { ProtectedRoute } from './auth/ProtectedRoute'
import { BookingDetails } from './pages/BookingDetails/async'
import { BookingList } from './pages/BookingList/async'
import { ChangeLog } from './pages/ChangeLog/async'
import { Contatcs } from './pages/Contatcs'
import { HotelDetails } from './pages/HotelDetails/async'
import { HotelList } from './pages/HotelList/async'
import { PriceComparison } from './pages/PriceComparison/async'
import { routes } from './routes'
import { useClickRegister } from './utils/useClickRegister'


export function App() {
  const account = useUnit($account)

  useClickRegister()

  React.useEffect(() => {
    fxInitApp()
  }, [])

  return (
    <HelmetProvider>
      <BrowserRouter>
        <Helmet>
          <script type="text/javascript">{`
          (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
              m[i].l=1*new Date();
              for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return }}
              k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
              (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
          `}
          </script>
          <script type="text/javascript">{`
            ym(${process.env.REACT_APP_YM_COUNTER}, "init", {clickmap:true,trackLinks:true,accurateTrackBounce:true,webvisor:true});
          `}
          </script>
          <noscript>{`
            <div>
              <img src="https://mc.yandex.ru/watch/${process.env.REACT_APP_YM_COUNTER}" style={{ position: 'absolute', left: '-9999px' }} alt="" />
            </div>
          `}
          </noscript>
        </Helmet>
        <AbacProvider permissions={account?.grants} rules={{}}>
          <Layout>
            <Routes>
              <Route path={routes.hotelList()} index element={<ProtectedRoute><HotelList /></ProtectedRoute>} />
              <Route path={routes.hotelDetails()} element={<ProtectedRoute><HotelDetails /></ProtectedRoute>} />
              <Route path={routes.bookingList()} index element={<ProtectedRoute><BookingList /></ProtectedRoute>} />
              <Route path={routes.changeLog()} index element={<ProtectedRoute><ChangeLog /></ProtectedRoute>} />
              <Route path={routes.bookingDetails()} index element={<ProtectedRoute><BookingDetails /></ProtectedRoute>} />
              <Route path={routes.priceComparison()} element={<ProtectedRoute grants={['view_tab_price_comparison']}><PriceComparison /></ProtectedRoute>} />
              <Route path={routes.contacts()} element={<Contatcs />} />
              <Route path={routes.login()} element={<Login />} />
              <Route path="*" element={<Navigate to={routes.hotelList()} replace />} />
            </Routes>
          </Layout>
        </AbacProvider>
      </BrowserRouter>
    </HelmetProvider>
  )
}
