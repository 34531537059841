import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Alert, Button, Form, Input, Typography } from 'antd'
import { useUnit } from 'effector-react'

import { getDocumentTitle } from '../../mappers/getDocumentTitle'
import { $account, $loginError, fxLoginUser } from '../account.model'
import css from './Login.module.css'


type LoginFormType = {
  login: string
  password: string
}

export const Login = () => {
  const location = useLocation()
  const loginError = useUnit($loginError)
  const account = useUnit($account)
  const navigate = useNavigate()

  React.useEffect(() => {
    document.title = getDocumentTitle('Авторизация')
  }, [])

  React.useEffect(() => {
    const redirectUrl = location.state?.from?.pathname ?? '/'
    if (account) navigate(redirectUrl, { replace: true })
  }, [account])

  return (
    <div className={css.container}>
      <Form
        className={css.form}
        initialValues={{ login: '', password: '' }}
        onFinish={fxLoginUser}
        autoComplete="off"
        validateTrigger="onSubmit"
        validateMessages={{
          required: 'Поле обязательно для заполнения',
          types: { email: 'Неверный e-mail' },
        }}
      >
        <Typography.Title level={4} className={css.heading}>
          Авторизация
        </Typography.Title>

        {!!loginError && (
          <Alert
            message={loginError || 'Ошибка авторизации'}
            type="error"
            className={css.error}
            showIcon
          />
        )}

        <div className={css.label}>Логин</div>
        <Form.Item<LoginFormType>
          name="login"
          rules={[{ required: true }, { type: 'email' }]}
          className={css.input}
        >
          <Input type="text" name="login" />
        </Form.Item>

        <div className={css.label}>Пароль</div>
        <Form.Item<LoginFormType>
          name="password"
          rules={[{ required: true }]}
          className={css.input}
        >
          <Input.Password type="password" name="password" />
        </Form.Item>

        <Form.Item className={css.submit}>
          <Button type="primary" htmlType="submit" block>
            Войти
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}
