/* eslint-disable max-len */
/* eslint-disable import/no-cycle */
/* istanbul ignore file */
import createClient from 'openapi-fetch'

import { AccessToken, RefreshToken } from '../auth'
import { fxRefreshToken } from '../auth/account.model'
import { isPublicRoute, routes } from '../routes'
import { components, operations, paths } from '../schema'


const commonHeaders = {
  'Content-Type': 'application/json',
  'Accept-Language': 'ru-RU',
}

const authFetch: typeof fetch = async (url, request) => {
  const response = await fetch(url, { ...request, headers: { ...commonHeaders } })

  if (response.status === 401) {
    const { pathname } = window.location
    if (pathname !== routes.login() && !isPublicRoute(pathname)) {
      window.location.href = routes.login()
    }
  }

  return response
}
const apiFetch: typeof fetch = async (url, request) => {
  const accessToken = AccessToken.get()
  const response = await fetch(url, { ...request, headers: { ...commonHeaders, Authorization: `Bearer ${accessToken}` } })

  if (response.status === 401) {
    const refreshToken = RefreshToken.get()

    if (refreshToken) {
      const updates = await fxRefreshToken({ refreshToken })
      return fetch(url, { ...request, headers: { ...commonHeaders, Authorization: `Bearer ${updates.accessToken}` } })
    }

    const { pathname } = window.location
    if (pathname !== routes.login() && !isPublicRoute(pathname)) {
      window.location.href = routes.login()
    }
  }

  if (response.status === 400 || response.status === 404 || response.status === 500) {
    throw new Error('Что-то пошло не так')
  }

  return response
}

const authClient = createClient<paths>({
  fetch: authFetch,
  credentials: 'include',
  headers: commonHeaders,
})
const apiClient = createClient<paths>({
  fetch: apiFetch,
  credentials: 'include',
  headers: commonHeaders,
})

export type PanelHotelDetails = components['schemas']['PanelHotelDetails']
export type LoginRequest = components['schemas']['LoginRequest']
export type LogoutRequest = components['schemas']['LogoutRequest']
export type RefreshTokenRequest = components['schemas']['RefreshTokenRequest']
export type Account = components['schemas']['Account']
export type OAuth2Tokens = components['schemas']['OAuth2Tokens']

export const API = {
  getPanelDictionaries: () => apiClient.GET('/api/panel/dictionaries'),
  getHotelIdsSuggestions: (query: operations['getCitiesSuggestions']['parameters']['query']) => apiClient.GET('/api/panel/hotels/ids/suggestions', { params: { query } }),
  getCitiesSuggestions: (query: operations['getCitiesSuggestions']['parameters']['query']) => apiClient.GET('/api/panel/cities/suggestions', { params: { query } }),

  queryPanelHotelSources: (body: components['schemas']['PanelHotelsQuery']) => apiClient.POST('/api/panel/hotels/sources/query', { body }),
  getPanelHotelSourceDetails: (path: operations['getPanelHotelSourceDetails']['parameters']['path']) => apiClient.GET('/api/panel/hotels/sources/{sourceId}', { params: { path } }),
  updateHotelBySourceId: (body: components['schemas']['UpdateHotelRequest']) => apiClient.PUT('/api/panel/hotel', { body }),

  queryPanelBookings: (body: components['schemas']['PanelBookingsQuery']) => apiClient.POST('/api/panel/bookings/query', { body }),
  getPanelBookingDetails: (path: operations['getPanelBookingDetails']['parameters']['path']) => apiClient.GET('/api/panel/bookings/{bookingId}', { params: { path } }),
  getPaymentCard: (path: operations['getPaymentCard']['parameters']['path']) => apiClient.GET('/api/panel/bookings/{bookingId}/payment-card', { params: { path } }),
  updatePaymentCard: (path: operations['paymentCardUpdateRequest']['parameters']['path']) => apiClient.PUT('/api/panel/bookings/{bookingId}/payment-card-update/request', { params: { path } }),

  checkHotelWarnings: (body: components['schemas']['UpdateHotelRequest']) => apiClient.PUT('/api/panel/hotel/check', { body }),
  queryReturnConditions: (path: operations['queryReturnConditions']['parameters']['path']) => apiClient.POST('/api/panel/bookings/{bookingId}/return/conditions', { params: { path }, body: { returnTs: undefined } }),
  searchBookingLogs: (path: operations['searchBookingLogs']['parameters']['path']) => apiClient.GET('/api/panel/bookings/{bookingId}/logs', { params: { path } }),

  cancelBookingRequest: (path: operations['cancelBookingRequest']['parameters']['path'], body: components['schemas']['PanelBaseCancelBookingRequest']) => apiClient.PUT('/api/panel/bookings/{bookingId}/cancel/request', { params: { path }, body }),
  addBookingComment: (path: operations['addBookingComment']['parameters']['path'], body: components['schemas']['PanelBookingCommentRequest']) => apiClient.POST('/api/panel/bookings/{bookingId}/comment', { params: { path }, body }),

  queryHotelUpdateHistory: (body: components['schemas']['HotelUpdateHistoryQuery']) => apiClient.POST('/api/panel/hotels/history/query', { body }),

  // auth
  putConsent: () => apiClient.PUT('/api/panel/legal/consent'), // согласие пользователя
  getAccount: () => apiClient.GET('/api/panel/auth/account').then(({ data }) => data ?? null),
  login: (body: LoginRequest) => authClient.POST('/api/panel/auth/login', { body }),
  logout: (body: LogoutRequest) => authClient.POST('/api/panel/auth/logout', { body }),
  refreshToken: (body: RefreshTokenRequest) => authClient.POST('/api/panel/auth/token', { body }).then(({ data }) => data as OAuth2Tokens),

  // 2FA
  sendOtp: (body: components['schemas']['SendOtpRequest']) => apiClient.POST('/api/panel/otp/send', { body }),
  verifyOtp: (body: components['schemas']['VerifyOtpRequest']) => apiClient.POST('/api/panel/otp/verify', { body }),
}
