/* istanbul ignore file */
import React from 'react'
import { useAbac } from 'react-abac'
import { NavLink, useLocation } from 'react-router-dom'
import { UserOutlined } from '@ant-design/icons'
import { Avatar, Dropdown } from 'antd'
import { useUnit } from 'effector-react/effector-react.umd'

import { routes } from '../../routes'
import { isInRoutesScope, Pattern } from '../../utils/isInRoutesScope'
import { $account, $isAcceptedTerms, $isAppInitialized } from '../account.model'
import { grant } from '../grant'
import css from './Header.module.css'
import { Logout } from './Logout'


type Nav = {
  href: string
  label: string
  scopes: Pattern[]
  permission: string
  dataYMClick?: string
}

export const Header: React.FC = () => {
  const { pathname } = useLocation()
  const account = useUnit($account)
  const isAppInitialized = useUnit($isAppInitialized)
  const isAcceptedTerms = useUnit($isAcceptedTerms)
  const { userHasPermissions } = useAbac()

  const hasNavigation = !!account && isAcceptedTerms
  const isVisibleLoginLink = isAppInitialized && !account && !isInRoutesScope([routes.login])(pathname)

  const navigation: Nav[] = !hasNavigation ? [] : [
    {
      label: 'Отели',
      href: routes.hotelList(),
      scopes: [routes.hotelList, routes.hotelDetails],
      permission: '', // виден всем
      dataYMClick: 'tab_hotels_list_click',
    },
    {
      label: 'Бронирования',
      href: routes.bookingList(),
      scopes: [routes.bookingList, routes.bookingDetails],
      permission: '', // виден всем
      dataYMClick: 'tab_booking_list_click',
    },
    {
      label: 'Сравнение цен',
      href: routes.priceComparison(),
      scopes: [routes.priceComparison],
      permission: grant('view_tab_price_comparison'),
    },
    {
      label: 'История изменений',
      href: routes.changeLog(),
      scopes: [routes.changeLog],
      permission: grant('view_change_history'),
    },
  ].filter(({ permission }) => !permission || userHasPermissions(permission))

  return (
    <div className={css.header}>
      <div className={css.nav}>
        {navigation.map(({ href, label, scopes, dataYMClick }) => (
          <NavLink
            to={href}
            className={css.link}
            data-current={isInRoutesScope(scopes)(pathname)}
            key={href}
            data-ym-click={dataYMClick}
          >
            <span>{label}</span>
          </NavLink>
        ))}
      </div>

      {!!account && (
        <Dropdown menu={{ items: [{ key: 'logout', label: <Logout /> }] }} placement="bottomRight">
          <div data-testid="profile">
            <span className={css.avatarLabel}>{account.email}</span>
            <Avatar
              size={40}
              style={{ backgroundColor: 'var(--blue-100)', color: 'var(--blue-600)' }}
              icon={<UserOutlined />}
            />
          </div>
        </Dropdown>
      )}

      {isVisibleLoginLink && (
        <div>
          <NavLink to={routes.login()} className={css.avatarLabel} data-pseudoLink>Войти</NavLink>
          <Avatar size={40} icon={<UserOutlined />} />
        </div>
      )}
    </div>
  )
}
