/* istanbul ignore file */
/** https://confluence.tourmachine.tech/pages/viewpage.action?pageId=2229601034 */

type Grant =
  | 'legal_agreement' // согласие пользователя

  | 'view_all_hotels_list'
  | 'use_filters'
  | 'view_parameters'

  | 'view_page_about_hotel'
  | 'view_page_about_rooms'
  | 'view_page_about_rooms_external'
  | 'view_page_about_treatment'
  | 'view_page_about_tariffs'
  | 'view_page_about_payment'
  | 'view_page_about_pricing'
  | 'view_page_about_agreement'

  | 'edit_hotel'
  | 'edit_hotel_priority'
  | 'edit_hotel_tags'
  | 'edit_hotel_publication'
  | 'edit_hotel_connection'
  | 'edit_hotel_tariffs'
  | 'edit_hotel_payment'
  | 'pricing_management'

  | 'view_tab_price_comparison'

  | 'request_card_update'
  | 'view_card_data'
  | 'request_booking_cancellation'
  | 'write_comment'

  | 'filter_by_all_statuses' // Фильтры статусов у отельеров должны быть только Подтверждено и Отменено остальное доступно по этому гранту
  | 'filter_by_provider'

  | 'view_change_history'


export const grant = (g: Grant) => g

