/* istanbul ignore file */

import { isInRoutesScope } from './utils/isInRoutesScope'


export const routes = {
  hotelList: () => '/hotels',
  hotelDetails: (sourceId = ':sourceId') => `/hotels/${sourceId}`,
  bookingList: () => '/bookings',
  changeLog: () => '/change-log',
  bookingDetails: (bookingId = ':bookingId') => `/bookings/${bookingId}`,
  priceComparison: () => '/price-comparison',
  login: () => '/login',
  contacts: () => '/contacts',
}

export const isPublicRoute = isInRoutesScope([
  routes.contacts,
])
