import { useEffect } from 'react'


const YCOUNTER = process.env.REACT_APP_YM_COUNTER
const DEBUGENABLED = true


export const ym = (targets: string[] | string) => {
  if (!YCOUNTER || !window?.ym) return
  (typeof targets === 'string' ? [targets] : targets)
    .forEach((target) => window.ym?.(YCOUNTER, 'reachGoal', target))
  // eslint-disable-next-line no-console
  if (DEBUGENABLED) console.info('%c REACH GOAL YM %c %s ', 'background: #f50; color: #fff', 'background: #ffefcc; color: #111', targets)
}

export const useClickRegister = () => {
  const limit = 5
  const getAttribute = (el: HTMLElement, attempts = 0): string | null => {
    const attr = el.getAttribute?.('data-ym-click') ?? null

    if (attr || attempts >= limit || !el.parentNode) {
      return attr
    }

    return getAttribute(el.parentNode as HTMLElement, attempts + 1)
  }

  const getYmClickAttr = (el: HTMLElement | null): string | null => {
    if (!el) {
      return null
    }

    return getAttribute(el)
  }

  useEffect(() => {
    const fn = (event: Event) => {
      const ymClick = getYmClickAttr(event.target as HTMLElement)
      if (!ymClick) return
      ym(ymClick.split(','))
    }
    document.addEventListener('click', fn, { capture: true })

    return () => document.removeEventListener('click', fn)
  }, [YCOUNTER])
}
