/* eslint-disable import/no-cycle */
import { createEffect, createStore, sample } from 'effector'

import { Account, API, OAuth2Tokens } from '../api'
import { grant } from './grant'
import { AccessToken, RefreshToken } from './index'


export const $account = createStore<Account | null>(null)
export const $loginError = createStore('')
export const $isAppInitialized = createStore(false)
export const $isAcceptedTerms = $account.map((account) => account?.grants.includes(grant('legal_agreement')) ?? false)

export const fxLoginUser = createEffect(API.login)
export const fxRefreshToken = createEffect(API.refreshToken)
export const fxLoadAccount = createEffect(API.getAccount)
export const fxUserAgree = createEffect(API.putConsent)
export const fxLogoutUser = createEffect(() => {
  const refreshToken = RefreshToken.get()
  if (refreshToken) return API.logout({ refreshToken })
  return Promise.resolve()
})
export const fxUpdateTokens = createEffect((tokens: OAuth2Tokens) => {
  AccessToken.set(tokens.accessToken)
  RefreshToken.set(tokens.refreshToken)
})
export const fxClearTokens = createEffect(() => {
  AccessToken.remove()
  RefreshToken.remove()
})
export const fxInitApp = createEffect(() => {
  if (AccessToken.get()) return fxLoadAccount()
})

sample({
  source: fxLoginUser.doneData,
  filter: ({ response }) => response.status === 200,
  fn: ({ data }) => data as OAuth2Tokens,
  target: fxUpdateTokens,
})
sample({
  source: fxUpdateTokens.done,
  target: fxLoadAccount,
})
sample({
  source: fxLogoutUser.finally,
  target: fxClearTokens,
})
sample({
  source: fxRefreshToken.doneData,
  target: fxUpdateTokens,
})
sample({
  source: fxLogoutUser,
  fn: () => null,
  target: $account,
})
sample({
  source: fxLoadAccount.doneData,
  fn: (account) => account ?? null,
  // fn: (account) => (account ? { ...account, grants: permissions } : null), // дебаг ограничений
  target: $account,
})
sample({
  source: fxLoginUser,
  fn: () => '',
  target: $loginError,
})
sample({
  source: fxLoginUser.done,
  fn: ({ result }) => ({
    INVALID_CREDENTIALS: 'Неверный e-mail или пароль',
    TEMPORARILY_BLOCKED: 'Учетная запись заблокирована',
    BLOCKED: 'Учетная запись заблокирована',
  })[result?.error?.code ?? ''] ?? '',
  target: $loginError,
})
sample({
  source: fxLoginUser.doneData,
  filter: ({ response }) => response.status === 500,
  fn: () => 'Сервер временно недоступен',
  target: $loginError,
})
sample({
  source: fxInitApp.finally, // это случается однажды при старте
  fn: () => true,
  target: $isAppInitialized,
})
sample({
  source: fxUserAgree.done, // после принятия согласия перезапросим аккаунт
  target: fxLoadAccount,
})
